/* eslint-disable react-hooks/exhaustive-deps */
import { OstetricaClass } from 'apps/ricerca_albo/models/ostetrica';
import http, {httpAlbo} from './http';



class ApiService {

    // Search Albo
    async searchAlbo(formData) {
        let result = await httpAlbo.post('search-albo/', { ...formData });
        return result.data.map((data) => new OstetricaClass(data));
    }
    
}

export default new ApiService();
