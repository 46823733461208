export class OstetricaClass {    
  
    constructor(data){
        this.name = data.CognomeNome;
        this.birth_date = data.DataNascita;
        this.birth_place = data.LuogoNascita;
        this.year_first_sub = data.AnnoPrimaIscrizione;
        this.title_year = data.DatarRilascioTitolo;
        this.order = data.Ordine;
        this.suspended_from = data.SospensioneDal;
        this.suspended_to = data.SospensioneAl;
    }
}